import React from "react";

import icon1 from "../assets/resume-icon1.png";
import icon2 from "../assets/resume-icon2.png";

function Resume() {
  return (
    <div className="App">

      
      <section className="py-5" id="resume">
        <h4 className="st-section-heading-title">Resume</h4>
        <h2 className="st-section-heading-subtitle">Resume</h2>  
        
        <div className="container mt-5 text-start">
          <div className="row">
          
            <div className="col-lg-6">
              <div className="st-height-b0 st-height-lg-b50"></div>
              <div className="st-resume-wrap">
                <div className="st-resume-heading">
                  <img src={icon1} alt="resume-icon"></img>
                  <h2 className="st-resume-heading-title">Education</h2>
                </div>
                <div className="st-height-b50 st-height-lg-b30"></div>
                <div className="st-resume-timeline-wrap">
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      Product Owner Certification
                    </h3>
                    <div className="st-resume-timeline-duration">
                      August 26,2022
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      SCRUM ALLIANCE®  <br /><a href="www.scrumalliance.org">www.scrumalliance.org</a>
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                        Completed the Scrum alliance training course and certified as a product owner.
                      </p>
                    </div>
                  </div>
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      Scrum Master Certification
                    </h3>
                    <div className="st-resume-timeline-duration">
                      December 08, 2021
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      SCRUM ALLIANCE®  <br /><a href="www.scrumalliance.org">www.scrumalliance.org</a>
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                      Completed the Scrum alliance training course and certified as a scrum master.
                      </p>
                    </div>
                  </div> 
                </div>
              </div>
              <div className="st-height-b100 st-height-lg-b80"></div>
            </div>
            <div className="col-lg-6">
              <div className="st-height-b0 st-height-lg-b50"></div>
              <div className="st-resume-wrap">
                <div className="st-resume-heading">
                <img src={icon2} alt="resume-icon"></img>
                  <h2 className="st-resume-heading-title">Experience</h2>
                </div>
                <div className="st-height-b50 st-height-lg-b30"></div>
                <div className="st-resume-timeline-wrap">
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      Senior UX/UI Designer - Vue.js
                    </h3>
                    <div className="st-resume-timeline-duration">
                      JUNE 2019–PRESENT
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      WTFast (AAA Internet Publishing, Inc)
                    </h4>
                    <div className="st-resume-timeline-text">
                      
                      <p>In my role as a senior front-end developer, I spearheaded the design of the primary WTFast web user interface, collaborating seamlessly with the back-end team to successfully launch a cutting-edge client application that remains in active use today.</p>
                      <p>Moreover, I assumed leadership responsibilities by guiding a proficient team in crafting both a mobile iteration and a hardware router version of our software client. As our team embraced agile methodologies, I took on the pivotal role of Scrum Master to ensure the seamless adoption of agile practices. Subsequently, recognizing my contributions and leadership, I was promoted to the position of Product Owner for the router team.</p>
                      
                    </div>
                  </div>
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      React.js Developer
                    </h3>
                    <div className="st-resume-timeline-duration">
                      MARCH 2020–JUNE  2020
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      Riot Racers
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                      During my personal time outside regular work hours, I undertook a side project dedicated to enhancing a crypto-powered virtual game. This endeavor involved orchestrating the recruitment of a small team of developers and overseeing the construction of a player tournament system integrated into the existing web application.</p>
                      <p>Effectively managing collaboration among myself, a graphic designer, a back-end developer, and an additional front-end developer, I successfully delivered this feature within the designated timeframe and budget constraints.
                      </p>
                    </div>
                  </div>
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">React.js Developer</h3>
                    <div className="st-resume-timeline-duration">
                      NOVEMBER 2017–JANUARY 2019
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      Lode.one (AGLIFE ENTERPRISES INC)
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                      Engaged in blockchain development, I focused on implementing smart contracts on the Ethereum platform to establish a cryptocurrency uniquely backed by a physical asset, namely silver. The project necessitated the creation of a multisig contract, enabling transparent auditing of vaulted silver holdings.</p>
                      <p>Taking advantage of a valuable learning opportunity, I traveled to Switzerland to collaborate with Ethereum developers. During this experience, I honed my skills in creating smart contracts using Solidity and gained a comprehensive understanding of Web3.</p>
                      <p>My contributions extended to designing and deploying the members portal, a crucial component of the public onboarding process for the initial startup program. Additionally, I delved into the practical application of Web3.js and Parity, exploring ways to seamlessly integrate blockchain transactions with a user-friendly web-based application, enhancing the accessibility of the technology for a broader audience.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="st-height-b100 st-height-lg-b80"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Resume;
