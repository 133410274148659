
function Ai() {


  return (
    <div className="App">
      <section className="py-5" id="about">
        <div className="container">
          <h4 className="st-section-heading-title">Lateral Thinking AI Embed</h4>
          <h2 className="st-section-heading-subtitle">Test page</h2>
        </div>
        <div className="container py-5 full-height mb-5">
          <div className="row full-height">

            <div className="col-lg-12  full-height">
              
                  <iframe src='https://lateralthinkingai.com/embed/CourseCreation/CourseTitle/a5309bec-be2e-4828-ba5a-bceaee816809' title='ai-results' width="100%" height="100%"></iframe>
      
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Ai;
