import React, { } from "react"

import Login from "../components/Login"

export default function AboutPage() {
    return (
        <div>
            <Login />
        </div>
    )
}
