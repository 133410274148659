import React from "react";

import WTFCapture from "../assets/WTFproject.png";
import RRCapture from "../assets/RRCapture.PNG";
import AGCapture from "../assets/AGLifeCapture.png";

function Portfolio() {
  return (
    <div className="App">
      <section className="py-5" id="Portfolio">
        <div className="container">
          <h4 className="st-section-heading-title">Portfolio</h4>
          <h2 className="st-section-heading-subtitle">Portfolio</h2>
        </div>
        <div className="container py-5">
          <div className="row mb-5">
            <div className="col-lg-6 p-5">
              <img
                src={WTFCapture}
                alt="WTFast Client"
                className="portfolio-img"
              ></img>
            </div>
            <div className="col-lg-6 text-start p-5">
              <h2 className="st-text-block-title">WTFast Gaming Client</h2>
              <h4 className="st-text-block-subtitle">Senior Developer</h4>
              <div className="st-text-block-text">
                <p>
                  The development of the WTFast gaming client posed various
                  challenges, particularly in establishing communication with
                  the installed service and ensuring seamless API connectivity
                  with the cloud. Custom authentication became a focal point,
                  leading to encounters with continuity issues and
                  synchronization challenges. My involvement included designing
                  the initial functional prototype and subsequently spearheading
                  a small team to deliver a production-ready version.
                </p>
                <p>DEVELOPMENT TOOLS</p>
                <ul>
                  <li>React</li>
                  <li>GoLang</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-6 p-5">
              <img
                src={RRCapture}
                alt="WTFast Client"
                className="portfolio-img"
              ></img>
            </div>
            <div className="col-lg-6 text-start p-5">
              <h2 className="st-text-block-title">
                Riot Racers Tournament System
              </h2>
              <h4 className="st-text-block-subtitle">React Developer</h4>
              <div className="st-text-block-text">
                <p>
                  The development of the RiotRacers portal posed a distinctive
                  challenge, demanding seamless integration with Metamask and a
                  cloud-based API. Despite the complexity, the platform
                  experienced a highly engaged user base, resulting in a
                  remarkable surge in traffic upon its successful initial
                  launch.
                </p>
                <p>DEVELOPMENT TOOLS</p>
                <ul>
                  <li>React</li>
                  <li>Node.js</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-6 p-5">
              <img
                src={AGCapture}
                alt="WTFast Client"
                className="portfolio-img"
              ></img>
            </div>
            <div className="col-lg-6 text-start p-5">
              <h2 className="st-text-block-title">Asset Management Portal</h2>
              <h4 className="st-text-block-subtitle">React Developer</h4>
              <div className="st-text-block-text">
                <p>
                  AGLife identified the necessity to enable its users to
                  seamlessly interact with both the blockchain and their
                  personal account information. The platform facilitated user
                  engagement not only with the product team but also with each
                  other. Utilizing a websocket layer and a cloud-based API,
                  relevant statistics, news, and information were efficiently
                  delivered to users, enhancing their overall experience.
                </p>
                <p>DEVELOPMENT TOOLS</p>
                <ul>
                  <li>React</li>
                  <li>PhP</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Portfolio;
