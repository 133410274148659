import React, { } from "react"

import Portfolio from "../components/Portfolio"

export default function PortfolioPage() {
    return (
        <div>
            <Portfolio />
        </div>
    )
}
