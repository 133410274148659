function Testimonials() {
  return (
    <div>
      <section className="py-5">
        <div className="container px-5 my-5 px-5">
          <div className="text-center mb-5">
            <h2 className="fw-bolder">Customer testimonials</h2>
            <p className="lead mb-0">Things People Say</p>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-body p-4">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bi bi-chat-right-quote-fill text-primary fs-1"></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-1 text-start">
                        Working with Kirk Walker has been an absolute pleasure!
                        His keen eye for design, coupled with his technical
                        proficiency, has significantly elevated the user
                        interface of our projects. Kirk is a collaborative team
                        player who brings creativity and precision to every
                        task. His commitment to delivering high-quality work and
                        his positive, can-do attitude make him an invaluable
                        asset to any development team.
                      </p>
                      <div className="small text-muted text-end">
                        - Gerry Charbanou, Kelowna BC
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body p-4">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bi bi-chat-right-quote-fill text-primary fs-1"></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-1 text-start">
                        Having Kirk Walker on our team as a React developer has
                        been a game-changer. Kirk's ability to translate complex
                        concepts into seamless user interfaces is outstanding.
                        With a keen attention to detail and a passion for clean,
                        efficient code, Kirk consistently exceeds expectations.
                        His collaborative approach and effective communication
                        make him a pleasure to work with.
                      </p>
                      <div className="small text-muted text-end">
                        - Tony Gianni, Pheonix AZ
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonials;
