import React, { } from "react"
import Header from "../components/Header"
import Testimonials from "../components/Testimonials"
import Features from "../components/Features"


export default function HomePage () {
    return (
        
        <div className="App">
            <Header />
            <Features />
            <Testimonials />
        </div>
    
    )
}
