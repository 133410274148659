import React from "react";

function Features() {
  return (
    <div>
      <section className="py-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="h4 fw-bolder">Process</h2>
              <p className="text-start">
                My meticulous approach is designed to safeguard your project
                from oversights that could jeopardize its success. Prior to
                delving into coding for each project, I conduct a comprehensive
                road-mapping session. During this session, I methodically define
                S.M.A.R.T. (Specific, Measurable, Attainable, Relevant,
                Time-bound) goals to lay a solid foundation for the project's
                success. This strategic planning ensures that every crucial
                element is considered and aligned with the project's objectives
                before implementation begins.
              </p>
            </div>
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="h4 fw-bolder">Experience</h2>
              <p className="text-start">
                Throughout my career, I've contributed my skills to a diverse
                range of environments, including digital agencies, large
                corporations, startups, and collaborating with solo founders. My
                professional journey includes extensive experience in liaising
                with clients and managing remote development teams, often
                spanning across various time zones—successfully coordinating
                with teams of 20 or more. With a decade of hands-on experience,
                I've acquired an acute awareness of potential pitfalls in custom
                web applications and website projects. Allow me to leverage my
                expertise to guide you safely through any challenges that may
                arise.
              </p>
            </div>
            <div className="col-lg-4">
              <h2 className="h4 fw-bolder">Satisfaction</h2>
              <p className="text-start">
                I implement continuous and automated testing for all developed
                features to guarantee the proper functionality of the
                application and to identify any potential bugs at an early
                stage. What starts as a one-off project often evolves into a
                lasting business relationship, with clients benefiting from
                sustained guidance and support well beyond the initial launch.
                Your success is not just a milestone but an ongoing commitment.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
