import React from "react";

import Hero from "../assets/kirk4.png";

function Header() {
  return (
    <div>
      <header className="py-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-5">
                <div className="text-start">
                  <p className="text-blue-50">Hello, I'm</p>
                  <h1 className="display-hero fw-bolder text-white mb-2">
                    Kirk Walker
                  </h1>
                  <p className="lead display-hero-sub mb-10">
                    UI/UX Developer
                  </p>
                </div>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-left">
                  <a
                    className="btn btn-primary btn-lg px-5 me-sm-2"
                    href="/contact"
                  >
                    Hire Me
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
            <img src={Hero} alt="Kirk Walker Portfolio" className="portfolio-img rounded-4"></img>
            </div>

          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;