import React from "react";

export default function NotFoundPage() {
  return (
    <div className="App">
      <section className="py-5 border-bottom" id="notfound">
        <div className="container full-height">
          <h4 className="st-section-heading-title">404 Not found</h4>
        </div>
      </section>
    </div>
  );
}
