import React from "react";
import logo from "../assets/logo.svg";

function Nav() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container px-5">
          <img src={logo} alt="Logo" width="40" className="App-logo" />
          &nbsp;
          <a className="navbar-brand" href="#!">
            Kirk J. Walker
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <CustomLink href="/">Home</CustomLink>
              </li>
              <li className="nav-item">
                <CustomLink href="/about">About</CustomLink>
              </li>
              <li className="nav-item">
                <CustomLink href="/resume">Resume</CustomLink>
              </li>
              <li className="nav-item">
                <CustomLink href="/portfolio">Portfolio</CustomLink>
              </li>
              <li className="nav-item">
                <CustomLink href="/contact">Contact</CustomLink>
              </li>
              <li className="nav-item">
                <div className="nav-link"><div className="sp-phone">|</div></div>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <div className="sp-phone">
                    <svg viewBox="0 0 513.64 513.64"><path d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z"></path></svg>
                    <div className="sp-phone-no">1-250-870-3393</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

function CustomLink({ href, children, ...props }) {
  const path = window.location.pathname;
  return (
    <a href={href} className={path === href ? "nav-link active" : "nav-link"}>
      {children}
    </a>
  );
}

export default Nav;
