import React, { } from "react"


function Footer() {
    return (
        <div>
            <footer className="py-3 bg-dark">
                <div className="container px-5"><p className="m-0 text-center text-white-50">Copyright &copy; Kirk Walker {(new Date().getFullYear())}</p></div>
            </footer>
        </div>
    )
}

export default Footer;