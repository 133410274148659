import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import Users from "./Users";
import useRefreshToken from "../hooks/useRefreshToken";

function Admin() {
  const navigate = useNavigate();
  const logout = useLogout();
  const refresh = useRefreshToken();

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <div className="App">
      <section className="py-5" id="about">
        <div className="container">
          <h4 className="st-section-heading-title">Admin</h4>
          <h2 className="st-section-heading-subtitle">Admin</h2>
        </div>
        <div className="container py-5 full-height">
          <div className="row">
          <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="st-vertical-middle">
                <div className="st-vertical-middle-in">
                  <h2 className="st-text-block-title">Current Users</h2>
                  <br />
                  <Users />

                  <div className="st-vertical-middle">
                    <button onClick={signOut}>Sign Out</button>
                    <button
                      onClick={() => {
                        refresh();
                      }}
                    >
                      Refresh Token
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Admin;
