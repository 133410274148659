import React from "react";

import Hero from "../assets/kirk.png";

function About() {
  return (
    <div className="App">
      <section className="py-5" id="about">
        <div className="container">
          <h4 className="st-section-heading-title">About Me</h4>
          <h2 className="st-section-heading-subtitle">About Me</h2>
        </div>
        <div className="container py-5 full-height">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="st-about-img-wrap">
                <img src={Hero} alt="About Kirk" className="portfolio-img  rounded-4"></img>
              </div>
              <div className="st-height-b0 st-height-lg-b30"></div>
            </div>
            <div className="col-lg-6">
              <div className="st-vertical-middle">
                <div className="st-vertical-middle-in">
                  <div
                    className="st-text-block st-style1 aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    <h2 className="st-text-block-title">
                      Hi There! I'm Kirk Walker
                    </h2>
                    <h4 className="st-text-block-subtitle">UI/UX Developer</h4>
                    <div className="st-text-block-text">
                      <p>
                      I'm a highly skilled and creative Front End Developer with a passion for crafting visually stunning and user-friendly applications. 
                      With a solid foundation in web development and a keen eye for design, I bring a unique blend of technical expertise and artistic flair 
                      to every project I undertake.
                      </p>
                    </div>
                    <ul className="st-text-block-details st-mp0">
                      <li>
                        <span>Phone</span> : <span>+1 250-870-3393</span>
                      </li>
                      <li>
                        <span>Email</span> : <span>kirk@kirkwalker.ca</span>
                      </li>
                      <li>
                        <span>From</span> :{" "}
                        <span>Kelowna, British Columbia</span>
                      </li>
                      <li>
                        <span>Language</span> : <span>English</span>
                      </li>
                      <li>
                        <span>Freelance</span> : <span>Available</span>
                      </li>
                      <li>
                        <span>Full-Time</span> : <span>Available</span>
                      </li>
                    </ul>
                    <div className="st-text-block-btn">
                      <a
                        className="st-btn st-style1 st-color1"
                        href="./assets/KirkWalkerResume2024.pdf"
                        download="KirkWalkerResume2024.pdf"
                      >
                        Download CV
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
