import React, {useEffect } from "react"

import Ai from "../components/Ai"

export default function AiPage() {
    useEffect(() => {
        document.title = "Kirk Walker - AI test page";
     }, []);
    return (
        <div>
            <Ai />
        </div>
    )
}
