import React, { } from "react"

import Resume from "../components/Resume"

export default function ResumePage() {
    return (
        <div>
            <Resume />
        </div>
    )
}
