import React, { } from "react"

import Admin from "../components/Admin"

export default function ContactPage() {
    return (
        <div>
            <Admin />
        </div>
    )
}
